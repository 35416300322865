import React from 'react'

class Faq extends React.Component {

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const { 
        location: {
          search
        },
        pathContext: {
          redirects   
        }
      } = this.props;
      if (redirects.length > 0) {
        redirects.forEach((item) => {
          const splitSlug = item.slug.split('?');
          if (`?${splitSlug[1]}` === search) {
            window.location.href = item.target;
          }
        });
      }
    }
  }

  render() {
    return (
      <div></div>
    );
  };
};

export default Faq;